<template>
  <div>
    <div class="select">
      <div class="every_select">
        <el-input
          placeholder="请输入公司名称"
          v-model="listQuery.customerName"
          style="width: 200px; margin-right: 10px"
          size="small"
          @keyup.enter="getList"
        />
        <el-button type="primary" @click="getList()" size="small" icon="Search"
          >搜索</el-button
        >
      </div>
      <el-button
        type="success"
        size="small"
        :disabled="this.sels.length === 0"
        @click="startsAll()"
        plain
        icon="Select"
        >批量审核通过</el-button
      >
    </div>
    <el-table
      :data="startsList"
      style="width: 98%; margin: 0 auto; margin-top: 20px"
      border
      height="680px"
      @selection-change="handleSelectContract"
      v-loading="listLoading"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="55" align="center" fixed />
      <el-table-column
        type="index"
        label="序号"
        width="70"
        align="center"
        fixed
      />
      <el-table-column
        prop="customerName"
        label="公司名称"
        align="center"
        fixed
        width="300"
      />
      <el-table-column
        prop="categoryType"
        label="业务类型"
        width="180"
        align="center"
      />
      <el-table-column
        prop="categoryName"
        label="业务名称"
        width="180"
        align="center"
      />
      <el-table-column
        prop="contractStartTime"
        label="合同开始时间"
        width="180"
        align="center"
      />
      <el-table-column
        prop="contractEndTime"
        label="合同结束时间"
        width="180"
        align="center"
      />
      <el-table-column
        prop="contractRenewTime"
        label="下次续费日期"
        width="180"
        align="center"
      />
      <el-table-column
        prop="contractAmount"
        label="合同金额"
        width="180"
        align="center"
      />
      <el-table-column
        prop="contractActualAmount"
        label="实收金额"
        width="180"
        align="center"
      />
      <el-table-column
        prop="disCodes"
        label="业务负责人"
        width="180"
        align="center"
      />
      <el-table-column prop="remark" label="备注" width="180" align="center" />
      <el-table-column label="操作" align="center" width="300" fixed="right">
        <template #default="scope">
          <el-button
            type="primary"
            plain
            size="small"
            @click="handleContract(scope.row)"
            >修改</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="handleReject(scope.row)"
            plain
            >驳回</el-button
          >

          <el-button
            type="success"
            size="small"
            plain
            @click="updateContractStarts(scope.row)"
            >审核通过</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="delContract(scope.row)"
            plain
            v-if="isAdmin == 1"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="select">
      <div></div>
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>
    <!-- 修改弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="contractDialogVisible"
      title="修改"
    >
      <el-form
        ref="contractRuleFormRef"
        :model="contractForm"
        :rules="rules"
        label-width="120px"
        status-icon
      >
        <el-form-item label="合同编码" prop="contractNum">
          <el-input v-model="contractForm.contractNum" />
        </el-form-item>
        <el-form-item label="业务名称" prop="category">
          <selectBusiness
            v-model:category="contractForm.category"
          ></selectBusiness>
        </el-form-item>
        <el-form-item label="合同开始时间" prop="contractStartTime">
          <el-date-picker
            v-model="contractForm.contractStartTime"
            type="date"
            label="请选择"
            placeholder="请选择"
            style="width: 100%"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item label="合同结束时间" prop="contractEndTime">
          <el-date-picker
            v-model="contractForm.contractEndTime"
            type="date"
            label="请选择"
            placeholder="请选择"
            style="width: 100%"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item label="续费日期">
          <el-date-picker
            v-model="contractForm.contractRenewTime"
            type="date"
            label="请选择"
            placeholder="请选择"
            style="width: 100%"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item label="合同金额" prop="contractAmount">
          <el-input v-model="contractForm.contractAmount" />
        </el-form-item>
        <el-form-item label="实收金额" prop="contractActualAmount">
          <el-input v-model="contractForm.contractActualAmount" />
        </el-form-item>
        <el-form-item label="联系人" prop="contactsName">
          <el-input v-model="contractForm.contactsName" />
        </el-form-item>
        <el-form-item label="联系电话" prop="contactsPhone">
          <el-input v-model="contractForm.contactsPhone" />
        </el-form-item>
        <el-form-item label="业务负责人" prop="userId">
          <business
            code="contractExamine"
            v-model:userId="contractForm.userId"
          ></business>
        </el-form-item>
        <el-form-item label="是否一次性" prop="disposable">
          <el-radio-group
            v-model="contractForm.disposable"
            @change="handleDisposable"
          >
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="contractForm.remark" type="textarea" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="contractDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="updateContract()"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 驳回弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="rejectFormVisible"
      title="驳回原因"
      height="50vh"
      :before-close="handleClose"
    >
      <div>
        <span>驳回原因：</span>
        <el-input
          v-model="rejectForm.remark"
          :rows="2"
          type="textarea"
          placeholder="请输入"
          style="margin-top: 20px"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="rejectFormVisible = false">取消</el-button>
          <el-button type="primary" @click="rejected()"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  getContractList,
  delContract,
  updateContract,
  saveContractStatus,
} from "../../../api/crm";
import selectBusiness from "../components/selectBusiness.vue";
import business from "../custom/components/business.vue";
export default {
  name: "contractExamine",
  components: {
    selectBusiness,
    business,
  },
  data() {
    let validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号！"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号！"));
      } else {
        return callback();
      }
    };
    return {
      isAdmin:this.$store.getters['user/user'].isAdmin,
      listLoading: false,
      sels: [],
      total: "",
      rejectFormVisible: false,
      listQuery: {
        limit: 20,
        page: 1,
        auditStatus: 1,
        customerName: null,
      },
      rejectForm: {
        auditStatus: 2,
        id: "",
        remark: "",
      },
      startsList: [],
      contractDialogVisible: false,
      contractForm: {
        contractNum: "",
        category: [],
        categoryType: "",
        categoryName: "",
        contractStartTime: "",
        contractEndTime: "",
        contractRenewTime: "",
        contractAmount: "",
        contractActualAmount: "",
        contactsName: "",
        contactsPhone: "",
        businessName: "",
        disposable: false,
        remark: "",
        id: "",
        auditStatus: null,
        userId: [],
      },
      rules: {
        contractNum: [
          { required: true, message: "请输入合同编码", trigger: "blur" },
        ],
        category: [
          { required: true, message: "请选择业务名称", trigger: "change" },
        ],
        contractStartTime: [
          { required: true, message: "请选择合同开始时间", trigger: "change" },
        ],
        contractEndTime: [
          { required: true, message: "请选择合同结束时间", trigger: "change" },
        ],
        contractAmount: [
          { required: true, message: "请输入合同金额", trigger: "blur" },
        ],
        contractActualAmount: [
          { required: true, message: "请输入实收金额", trigger: "blur" },
        ],
        contactsName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        userId: [
          {
            required: true,
            message: "请选择业务负责人",
            trigger: "change",
          },
        ],
        disposable: [
          { required: true, message: "请选择是或否", trigger: "change" },
        ],
        contactsPhone: [
          { required: true, validator: validateMobile, trigger: "blur" },
        ],
      },
    };
  },

  mounted() {
    this.getList();
    this.initBus();
  },

  methods: {
    initBus() {
      this.$bus.off("contractUncheck");
      this.$bus.on("contractUncheck", (val) => {
        this.getList();
      });
    },
    getList() {
      this.listLoading = true;
      getContractList(this.listQuery).then((res) => {
        this.listLoading = false;
        if (res.data.msg == "success") {
          this.startsList = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    handleSelectContract(sels) {
      this.sels = sels;
    },
    //一次性变化设置
    handleDisposable(val) {
      if (val == 0) {
        this.contractForm.contractRenewTime = this.contractForm.contractEndTime;
      }
      if (val == 1) {
        this.contractForm.contractRenewTime = "";
      }
    },
    //修改合同
    handleContract(row) {
      this.contractForm = Object.assign({}, row);
      this.contractForm.category = [];
      this.contractForm.category[0] = this.contractForm.caType;
      this.contractForm.category[1] = this.contractForm.caName;
      this.contractDialogVisible = true;
      this.$nextTick(() => {
        this.$refs["contractRuleFormRef"].clearValidate();
      });
    },
    updateContract() {
      this.$refs["contractRuleFormRef"].validate((valid) => {
        if (valid) {
          this.contractForm.auditStatus = 1;
          this.contractForm.contractAmount =
            (this.contractForm.contractAmount * 1).toFixed(2) * 1;
          this.contractForm.contractActualAmount =
            (this.contractForm.contractActualAmount * 1).toFixed(2) * 1;
          this.contractForm.categoryType = this.contractForm.category[0];
          this.contractForm.categoryName = this.contractForm.category[1];
          updateContract(this.contractForm).then((res) => {
            if (res.data.msg == "success") {
              this.contractDialogVisible = false;
              this.$qzfMessage("修改成功");
              this.getList();
              this.$bus.emit("contractUpdate");
            }
          });
        }
      });
    },
    //删除合同
    delContract(row) {
      this.$confirm("确认删除合同吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delContract({ id: row.id }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("删除成功");
              this.getList();
              this.$bus.emit("contractUpdate");
            }
          });
        })
        .catch(() => {});
    },
    //单独审核通过
    updateContractStarts(row) {
      this.$confirm("确认通过审核吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          updateContract({ id: row.id, auditStatus: 3 }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("修改成功");
              this.getList();
              this.$bus.emit("contractUpdate");
            }
          });
        })
        .catch(() => {});
    },
    //批量审核通过
    startsAll() {
      this.$confirm("确认全部通过审核吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = [];
          this.sels.map((item) => {
            ids.push({
              id: item.id,
              auditStatus: 3,
            });
          });
          saveContractStatus(ids).then((res) => {
            if (res.data.msg == "success") {
              this.getList();
              this.$bus.emit("contractUpdate");
              this.$qzfMessage("修改成功");
            }
          });
        })
        .catch(() => {});
    },
    //合同驳回
    handleReject(row) {
      this.rejectForm.id = row.id;
      this.rejectFormVisible = true;
    },
    //对话框关闭的回调
    handleClose() {
      this.resetRejectForm();
      this.rejectFormVisible = false;
    },
    resetRejectForm() {
      this.rejectForm = {
        id: "",
        remark: "",
        auditStatus: 2,
      };
    },
    rejected() {
      updateContract(this.rejectForm).then((res) => {
        if (res.data.msg == "success") {
          this.getList();
          this.$bus.emit("contractUpdate");
          this.$qzfMessage("修改成功");
          this.rejectFormVisible = false;
          this.resetRejectForm();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  width: 98%;
  display: flex;
  margin: 0 auto;
  margin-top: 20px;
  justify-content: space-between;
}
.el-form-item {
  width: 46% !important;
}
</style>